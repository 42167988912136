import styled from '@emotion/styled';
import { Icon } from '../../common';

const StyledRadio = styled.label`

    display: flex;
    align-items: center;
    // flex-wrap: wrap;
   cursor: ${props => props.cursor || 'pointer'};

    .text {
        font-size: ${props => props.fontSize || '12px'};
        font-weight: 500;
        color: var(--quadColor);
    }

    .material-icons {
        font-size: 16px;
        margin-right: 5px;
    }

    .unchecked {
        color: #E2E2E2;
        
    }

    .checked {
        display: none;
        color: var(--primaryColor);
    }

    input {
        display: none;

        :checked ~ .checked {
            display: inline-block;
        }

        :checked ~ .unchecked {
            display: none;
        }

        :checked ~ .text {
            color: var(--primaryColor);
            font-weight: 800;
        }
    }
`

export default function Radio({ children, name, value, checked, disabled=false, className='', fontSize,cursor, onChange }) {
    return <StyledRadio className={className} fontSize={fontSize} cursor={cursor}>
        <input type="radio" name={name} value={value} checked={checked} onChange={onChange} disabled={disabled} />
        <Icon className="unchecked">radio_button_unchecked</Icon>
        <Icon className="checked">radio_button_checked</Icon>
        <span className="text">{children}</span>
    </StyledRadio>
}