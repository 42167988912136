import { Button } from '.';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_GENERATE_SUMMARY_DATA } from '../constants/analysis/generateSummaryConstants';
import { REQUEST_SUMMARYDATA } from '../constants/field/GetSummaryDataConstant';
import styled from '@emotion/styled';
import spinner from '../images/tube-spinner3.svg';
import * as GetSummaryDataMethods from '../utils/api/field/GetSummaryData';

const CustomButton = styled(Button)`
  display: inline;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--midWhite);
  color: var(--sapGreen);
  border-radius: 3px;
  padding: 0.4rem 0.9rem;
  img {
    padding-right: 5px;
  }
  :hover {
    background: var(--midWhite);
    color: ${props => props.buttonColor};
  }

  :disabled,
  [disabled] {
    background: #f2f4f4;
    border: none;
    color: #a6a6a6;

    :hover {
      background: var(--midWhite);
      border: none;
      color: var(--middleGrey);
      cursor: not-allowed;
    }
  }
`;

export default function SummaryTextContainer(props) {
  const dispatch = useDispatch();

  const summaryData = useSelector(state => state.getSummaryReducer.Analysisdata);
  const generateSummary = useSelector(state => state.generateSummaryReducer.success);
  const [summaryFlag, setSummaryFlag] = useState(false);
  const [generateSuccess, setGenerateSuccess] = useState(false);
  const [viewSummary, setViewSummary] = useState(false);

  const displaySummary = summaryData => {
    if (props.isLoadingVideo === true) {
      props.setIsLoadingView(false);
    }
    props.setIsLoading(false);

    if (summaryData && summaryData[0]?.analysis !== '' && props.summaryWithQid !== undefined) {
      props.setSummaryWithQid([...props.summaryWithQid, { key: props.question_ID, value: summaryData[0]?.analysis }]);
    }

    if (summaryData && summaryData[0]?.analysis !== undefined && props.summary !== undefined) {
      if ((summaryData && summaryData[0]?.analysis === '') || summaryData[0]?.is_valid === 0) {
        props.setSummary(
          'System is unable to generate insights at the moment, please try again later OR contact admin'
        );
      } else {
        props.setSummary(summaryData[0]?.analysis);
        if (summaryData && summaryData[0]?.analysis?.length > 250) {
          var sliced = summaryData[0]?.analysis?.slice(0, 250);
          var lastIndex = sliced?.lastIndexOf(' ');

          props.setSliceSummary(sliced.slice(0, lastIndex));
        }
      }
    }

    if (summaryData && summaryData[0]?.analysis === undefined) {
      props.setSummary('System is unable to generate insights at the moment, please try again later OR contact admin');
    }

    props.setSelectedGenerateSummary([...props.selectedGenerateSummary, props.question_ID]);
  };

  var text = 'View Summary';

  if (props.type == 'audio') {
    if (props.mp3Flag == 1) {
      text = 'Update Summary';
    }
    if (props.isSummary === 0) {
      text = 'Generate Summary';
    }
  } else if (props.isMp4) {
    if (props.mp3Flag == 1) {
      text = 'Generate Summary';
    }
  } else {
    if (props.flag === 1) {
      text = 'Update Summary';
    }

    if (props.isSummary === 0) {
      text = 'Generate Summary';
    }
  }

  const handleGenerateText = async () => {
    // props.setIsLoading(true);
    const id = props.id;

    if (text === 'View Summary') {
      const promise = GetSummaryDataMethods.GetSummaryData({
        project_ID: props.project_id,
        question_ID: props.question_ID
      });

      promise.then(val => {
        console.log(val.res.Response.Analysisdata);
        displaySummary(val.res.Response.Analysisdata);
        // setSummaryFlag(true);
      });
    }

    if (text === 'Generate Summary') {
      props.setIsLoading(true);
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      props.setSelectedGenerateSummary([...props.selectedGenerateSummary, props.question_ID]);

      if (props.type === 'video' && props.isMp4 === false) {
        await fetch(
          `https://${process.env.REACT_APP_PYTHON_API}/export_clip_transcript?project_id=${props.project_id}&clip_id=${props.id}`,
          requestOptions
        )
          .then(response => response.json())
          .then(result => {
            if (result.success == true) {
              if (
                result?.data?.analysis?.data == null ||
                result?.data?.analysis?.data[1] === 'No transcript available'
              ) {
                props.setSummary(
                  'No transcripts available in the video to generate summary! Please edit the video to generate transcripts.'
                );
                props.setIsLoading(false);
              } else {
                const promise = GetSummaryDataMethods.GetSummaryData({
                  project_ID: props.project_id,
                  question_ID: props.question_ID
                });

                promise.then(val => {
                  console.log(val.res.Response.Analysisdata);
                  displaySummary(val.res.Response.Analysisdata);
                  // setSummaryFlag(true);
                });
              }
            } else {
              props.setSummary('No analysis has been generated due to some internal error.');
              props.setIsLoading(false);
              console.log('ERROR WHILE SUMMARY', result?.error_msg);
              const data = {
                status: 400,
                error: result?.error_msg.replace(/["']/g, ' '),
                question_ID: props?.question_ID,
                project_ID: props?.project_id,
                question_Type: 'Video',
                reason: 'Error from generate summary :' + result?.error_msg
              };
              dispatch({
                type: 'REQUEST_AI_LOGS',
                payload: data
              });
              console.log('REASONN', data);
            }
          })
          .catch(error => console.log('error', error));
      } else if (props.isMp4 === true) {
        await fetch(
          `https://${process.env.REACT_APP_PYTHON_API}/complete_video_transcript?que_id=${props.question_ID}`,
          requestOptions
        )
          .then(response => response.json())
          .then(result => {
            if (result.success == true) {
              if (result?.data?.data == null || result?.data?.data === 'No transcript text found for given question') {
                props.setSummary('No transcripts are available in the audio to generate summary!');
                props.setIsLoading(false);
              } else if (result?.data?.data[0].toLowerCase().includes('summary') == false) {
                props.setSummary('Please reupload videos to be able to generate summary.');
                props.setIsLoading(false);
              } else {
                const promise = GetSummaryDataMethods.GetSummaryData({
                  project_ID: props.project_id,
                  question_ID: props.question_ID
                });

                promise.then(val => {
                  console.log(val.res.Response.Analysisdata);
                  displaySummary(val.res.Response.Analysisdata);
                  // setSummaryFlag(true);
                });
              }
            } else {
              props.setSummary('No analysis has been generated due to some internal error.');
              props.setIsLoading(false);
              console.log('ERROR WHILE SUMMARY', result?.error_msg);
              const data = {
                status: 402,
                error: result?.error_msg.replace(/["']/g, ' '),
                question_ID: props?.question_ID,
                project_ID: props?.project_id,
                question_Type: 'Video_MP4',
                reason: 'Error from generate summary :' + result?.error_msg
              };
              dispatch({
                type: 'REQUEST_AI_LOGS',
                payload: data
              });
              console.log('REASONN', data);
            }
          })
          .catch(error => console.log('error', error));
      } else {
        await fetch(
          `https://${process.env.REACT_APP_PYTHON_API}/complete_audio_transcript?que_id=${props.question_ID}`,
          requestOptions
        )
          .then(response => response.json())
          .then(result => {
            if (result.success == true) {
              if (result?.data?.data == null || result?.data?.data === 'No transcript text found for given question') {
                props.setSummary('No transcripts are available in the audio to generate summary!');
                props.setIsLoading(false);
              } else {
                const promise = GetSummaryDataMethods.GetSummaryData({
                  project_ID: props.project_id,
                  question_ID: props.question_ID
                });

                promise.then(val => {
                  console.log(val.res.Response.Analysisdata);
                  displaySummary(val.res.Response.Analysisdata);
                  // setSummaryFlag(true);
                });
              }
            } else {
              props.setSummary('No analysis has been generated due to some internal error.');
              props.setIsLoading(false);
              console.log('ERROR WHILE SUMMARY', result?.error_msg);
              const data = {
                status: 402,
                error: result?.error_msg.replace(/["']/g, ' '),
                question_ID: props?.question_ID,
                project_ID: props?.project_id,
                question_Type: 'Audio',
                reason: 'Error from generate summary :' + result?.error_msg
              };
              dispatch({
                type: 'REQUEST_AI_LOGS',
                payload: data
              });
              console.log('REASONN', data);
            }
          })
          .catch(error => console.log('error', error));
      }
    }

    if (text === 'Update Summary') {
      props.setIsLoading(true);
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      props.setSelectedGenerateSummary([...props.selectedGenerateSummary, props.question_ID]);

      if (props.type === 'video' && props.isMp4 === false) {
        await fetch(
          `https://${process.env.REACT_APP_PYTHON_API}/export_clip_transcript?project_id=${props.project_id}&clip_id=${props.id}`,
          requestOptions
        )
          .then(response => response.json())
          .then(result => {
            if (result.success == true) {
              if (
                result?.data?.analysis?.data == null ||
                result?.data?.analysis?.data[1] === 'No transcript available'
              ) {
                props.setSummary(
                  'No transcripts available in the video to generate summary! Please edit the video to generate transcripts.'
                );
                props.setIsLoading(false);
              } else {
                const promise = GetSummaryDataMethods.GetSummaryData({
                  project_ID: props.project_id,
                  question_ID: props.question_ID
                });

                promise.then(val => {
                  console.log(val.res.Response.Analysisdata);
                  displaySummary(val.res.Response.Analysisdata);
                  // setSummaryFlag(true);
                });
              }
            } else {
              props.setSummary('No analysis has been generated due to some internal error.');
              props.setIsLoading(false);
              console.log('ERROR WHILE UPDATE SUMMARY', result?.error_msg);
              const data = {
                status: 401,
                error: result?.error_msg,
                question_ID: props?.question_ID,
                project_ID: props?.project_id,
                question_Type: 'Video',
                reason: 'Error from update summary :' + result?.error_msg
              };
              dispatch({
                type: 'REQUEST_AI_LOGS',
                payload: data
              });
              console.log('REASONN', data);
            }
          })
          .catch(error => console.log('error', error));
      } else if (props.isMp4 == true) {
        await fetch(
          `https://${process.env.REACT_APP_PYTHON_API}/complete_video_transcript?que_id=${props.question_ID}`,
          requestOptions
        )
          .then(response => response.json())
          .then(result => {
            if (result.success == true) {
              if (result?.data?.data == null || result?.data?.data === 'No transcript text found for given question') {
                props.setSummary('No transcripts are available in the audio to generate summary!');
                props.setIsLoading(false);
              } else if (result?.data?.data[0].toLowerCase().includes('summary') == false) {
                props.setSummary('Please reupload videos to be able to generate summary.');
                props.setIsLoading(false);
              } else {
                const promise = GetSummaryDataMethods.GetSummaryData({
                  project_ID: props.project_id,
                  question_ID: props.question_ID
                });

                promise.then(val => {
                  console.log(val.res.Response.Analysisdata);
                  displaySummary(val.res.Response.Analysisdata);
                  // setSummaryFlag(true);
                });
              }
            } else {
              props.setSummary('No analysis has been generated due to some internal error.');
              props.setIsLoading(false);
              console.log('ERROR WHILE UPDATE SUMMARY', result?.error_msg);
              const data = {
                status: 403,
                error: result?.error_msg,
                question_ID: props?.question_ID,
                project_ID: props?.project_id,
                question_Type: 'Video_MP4',
                reason: 'Error from update summary :' + result?.error_msg
              };
              dispatch({
                type: 'REQUEST_AI_LOGS',
                payload: data
              });
              console.log('REASONN', data);
            }
          })
          .catch(error => console.log('error', error));
      } else {
        await fetch(
          `https://${process.env.REACT_APP_PYTHON_API}/complete_audio_transcript?que_id=${props.question_ID}`,
          requestOptions
        )
          .then(response => response.json())
          .then(result => {
            if (result.success == true) {
              if (result?.data?.data == null || result?.data?.data === 'No transcript text found for given question') {
                props.setSummary('No transcripts are available in the audio to generate summary!');
                props.setIsLoading(false);
              } else {
                const promise = GetSummaryDataMethods.GetSummaryData({
                  project_ID: props.project_id,
                  question_ID: props.question_ID
                });

                promise.then(val => {
                  console.log(val.res.Response.Analysisdata);
                  displaySummary(val.res.Response.Analysisdata);
                  // setSummaryFlag(true);
                });
              }
            } else {
              props.setSummary('No analysis has been generated due to some internal error.');
              props.setIsLoading(false);
              console.log('ERROR WHILE UPDATE SUMMARY', result?.error_msg);
              const data = {
                status: 403,
                error: result?.error_msg,
                question_ID: props?.question_ID,
                project_ID: props?.project_id,
                question_Type: 'Audio',
                reason: 'Error from update summary :' + result?.error_msg
              };
              dispatch({
                type: 'REQUEST_AI_LOGS',
                payload: data
              });
              console.log('REASONN', data);
            }
          })
          .catch(error => console.log('error', error));
      }
    }
  };

  return (
    <>
      {props.selectedGenerateSummary !== undefined && props.selectedGenerateSummary.includes(props.question_ID) ? (
        <CustomButton
          fontWeight="400"
          fontSize="10px"
          className="ml-2"
          size="small"
          variant="outlined"
          buttonColor="#F2F4F4"
          onClick={handleGenerateText}
          disabled
        >
          {props.isLoading === true ? (
            <>
              <>
                {text === 'Update Summary' ? 'Updating Summary' : 'Generating Summary'}
                <img
                  src={spinner}
                  style={{ width: '20px', transform: 'scale(1,-1)', marginLeft: '0.3rem', paddingRight: '0px' }}
                />
              </>
            </>
          ) : (
            <>{text}</>
          )}
        </CustomButton>
      ) : (
        <Button
          className="ml-4"
          size="small"
          width="50px"
          data-toggle="modal"
          data-target="#exampleModalCenter"
          onClick={handleGenerateText}
        >
          {text}
        </Button>
      )}
    </>
  );
}
