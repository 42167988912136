// import styled from '@emotion/styled';
// import React from 'react';
// import { useParams, useLocation } from 'react-router-dom';
// import { Heading, Icon, Button } from '../../common';
// import { TextArea } from '../../components/form';
// import { useState, useEffect, useRef } from 'react';
// import { useDispatch, useSelector, connect } from 'react-redux';
// import SearchableDropdown from '../../components/form/SearchableDropdown';
// import Dropdown from '../../components/form/TaskTypeOption';
// import { REQUEST_PRE_EDITOR_DATA, UPDATE_EDITOR_TRANSCRIPT_DATA } from '../../constants/field/FieldPreEditorConstant';
// import { UPDATE_RTM_TRANSCRIPT_DATA } from '../../constants/projects/ProjectConstants';
// import { jsPDF } from 'jspdf';
// import 'jspdf-autotable';

// import { global_newglobal } from './EditTab';
// const CustomButton = styled(Button)`
//   width: ${props => props.width};
//   font-size: 12px;
//   padding: 0.2rem 0.5rem !important;
// `;

// const CustomTextArea = styled(TextArea)`
// 	::placeholder,
// 	::-webkit-input-placeholder,
// 	::-moz-placeholder,
// 	:-ms-input-placeholder {
// 	  color: white;
// 		font-weight: 400;
// 		font-size: 10px;
// 	}
// `;
// const TextInput = styled.input`
// 	border: 0;
// 	background: transparent;
// 	resize: none;
// 	font-size: 11px;
// 	font-weight: 500;
// 	color: red;
// 	width: 100%;
// 	margin: 0;
// 	padding: 0;

// 	&:focus-visible {
// 		border: 0;

// 		outline: none;
// 	}
// `;

// const IconCustom = styled(Icon)`
// 	font-size: 0.75rem;
// 	// margin-top: 7px;
// `;

// const FormContainer = styled.div`
// 	display: flex;
// 	width: auto;
// 	padding: 0 8px;
// 	margin: 0;
// 	border: ${(props) =>
// 		props.color
// 			? `1px solid ${props.color}`
// 			: `1px solid var(--greyBorder)`};
// 	border-radius: 20px;
// 	.css-tlfecz-indicatorContainer {
// 		display: none;
// 	}
// 	input {
// 		font-size: 11px;
// 		font-weight: 500;
// 		color: ${(props) =>
// 		props.color ? `${props.color} ` : `var(--white)`};

// 		color: red;
// 	}
// 	span {
// 		align-self: flex-end;
// 		margin-bottom: 0.5rem;
// 	}
// `;

// function Input({ className, submit, Options, value = '', color, singleValueStyle, clipId, tagDeleted, projectId, selectedRespondantVideoId, tagsList, selectedSideTab, setShowResults }) {

// 	const { projectRepondentVideo_ID } = useSelector(state => state.GetCaptureGuideReducer.captureguideData);
// 	const formRef = useRef(null);
// 	const loggedUser = useSelector(state => state.LoginReducer);
// 	//const[isConfirmationOpen,setIsConfirmationOpen] = useState(false);
// 	//const { projectId } = useParams();
// 	let location = useLocation();
// 	//console.log("loaction state in video tag : ", location.state)
// 	const dispatch = useDispatch();

// 	const [text, setText] = useState({ "value": value.tag_ID, "label": value.tagName });
// 	const [edit, setEdit] = useState(false);

// 	useEffect(() => {
// 		setText({ "value": value.tag_ID, "label": value.tagName });
// 	}, [tagsList])

// 	const handleSubmit = (e) => {
// 		e.preventDefault();
// 		submit(text);
// 		setEdit(true);
// 		//saveTag(text.label);

// 	};

// 	useEffect(() => {
// 		const handleOutsideForm = (e) => {
// 			if (formRef.current && !formRef.current.contains(e.target)) {
// 				setEdit(false);
// 			}
// 		};

// 		document.addEventListener('click', handleOutsideForm);

// 		return () => {
// 			document.removeEventListener('click', handleOutsideForm);
// 		}
// 	}, []);

// 	const saveTag = (tag) => {
// 		let payload = {
// 			"user_ID": loggedUser.userId,
// 			"clip_ID": clipId,
// 			"projectTagName": tag,
// 			"project_ID": projectId,
// 			"projectRepondentVideo_ID": projectRepondentVideo_ID
// 		}
// 		dispatch({ type: 'REQUEST_ADDVIDEOTAG', payload: payload });
// 		setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
// 			() => {
// 				getUpdatedPreEditorData();
// 				setText({ "value": value.tag_ID, "label": value.tagName });
// 				submit(text);

// 			},
// 			500
// 		);
// 		setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
// 			() => {
// 				document.getElementById(selectedSideTab).click();
// 				setEdit(false);
// 				setShowResults(false);
// 				submit(text);
// 			},
// 			3000
// 		);
// 	}
// 	//for update
// 	const deleteTag = (tag_id) => {
// 		// const isConfirmed = window.confirm("Are you sure wanted to delete or update this tag?");
// 		// if(isConfirmed){
// 		// 	let payload = {
// 		// 	"user_ID": loggedUser.userId,
// 		// 	"tag_ID": tag_id
// 		// }
// 		// dispatch({ type: 'REQUEST_DELETEVIDEOTAG', payload: payload });
// 		// tagDeleted(tag_id);
// 		// setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
// 		// 	() => getUpdatedPreEditorData(),
// 		// 	500
// 		//   );
// 		//   setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
// 		//   () => {
// 		// 	  document.getElementById(selectedSideTab).click();
// 		// 	  submit(text);
// 		// 	  setShowResults(false);
// 		//   },
// 		//   3000
// 		// );
// 		// }else{
// 		// 	alert('Deletion or updation of tag is cancel by user')
// 		// }
// 		let payload = {
// 			"user_ID": loggedUser.userId,
// 			"tag_ID": tag_id
// 		}
// 		dispatch({ type: 'REQUEST_DELETEVIDEOTAG', payload: payload });
// 		tagDeleted(tag_id);
// 		setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
// 			() => getUpdatedPreEditorData(),
// 			500
// 		);
// 		setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
// 			() => {
// 				document.getElementById(selectedSideTab).click();
// 				submit(text);
// 				setShowResults(false);
// 			},
// 			500
// 		);
// 	}

// 	const handleUpdate = () => {
// 		const newTagValue = window.prompt('Enter the updated tag:', text.label);

// 		if (newTagValue !== null) {
// 			updateTag(text.value, newTagValue);
// 		}
// 	};

// 	const updateTag = (tag_id, newTagValue) => {
// 		const deletePayload = {
// 			"user_ID": loggedUser.userId,
// 			"tag_ID": tag_id
// 		};

// 		dispatch({ type: 'REQUEST_DELETEVIDEOTAG', payload: deletePayload });

// 		// setTimeout(() =>{
// 		const addPayload = {
// 			"user_ID": loggedUser.userId,
// 			"clip_ID": clipId,
// 			"projectTagName": newTagValue,
// 			"project_ID": projectId,
// 			"projectRespondentVideo_ID": projectRepondentVideo_ID,
// 			"tag_ID": tag_id
// 		};
// 		dispatch({ type: 'REQUEST_ADDVIDEOTAG', payload: addPayload });

// 		setTimeout(() => {
// 			getUpdatedPreEditorData();
// 			setEdit(false);

// 		}, 500)
// 		setTimeout(() => {
// 			document.getElementById(selectedSideTab).click();
// 			setShowResults(false);
// 			setEdit(false);
// 		}, 1000)
// 		// },500)
// 	}

// 	const handleDropdownChange = (opt) => {
// 		setEdit(true);
// 		const newTagValue = opt.label;
// 		updateTag(text.value, newTagValue);
// 	}

// 	const onClickInput = () => {
// 		console.log("Input clicked");
// 		setEdit(true);
// 	}

// 	const getUpdatedPreEditorData = () => {
// 		dispatch({
// 			type: 'REQUEST_PRE_EDITOR_DATA',
// 			payload: {
// 				user_ID: loggedUser.userId,
// 				project_ID: projectId,
// 				projectRespondent_ID: projectRespondent_ID
// 			}
// 		});
// 	};

// 	return (
// 		<FormContainer ref={formRef} color={color} className={className} disable={!edit}>
// 			<form onSubmit={handleSubmit} onClick={onClickInput} >
// 				<SearchableDropdown
// 					isSearchable
// 					singleValueStyle={singleValueStyle}
// 					valueContainerStyle={{
// 						padding: edit ? '0 20px 0 0px' : '0 10px 0 0px',
// 					}}
// 					controlStyle={{ minHeight: 'auto' }}
// 					inputStyle={{
// 						color: color,
// 					}}
// 					selectedColor={color}
// 					defaultValue={text}
// 					options={Options}
// 					// onChange={(opt) => {
// 					// 	//setText(opt);
// 					// 	setEdit(true);
// 					// 	saveTag(opt.label);
// 					// 	submit(text);
// 					// }}
// 					onChange={handleDropdownChange}
// 				/>
// 				{/* <button onClick={handleUpdate}>Update tag</button> */}

// 			</form>

// 			<div style={{ height: '12px' }}>
// 				<IconCustom

// 					color={color}
// 					onClick={(e) => {
// 						deleteTag(text.value)
// 					}}
// 					style={{ cursor: 'pointer' }}
// 				>
// 					{/* {'close'} */}
// 					{!edit ? 'close' : 'subdirectory_arrow_left'}
// 				</IconCustom>
// 				{/* <IconCustom

// 					color={color}
// 					onClick={
// 						handleUpdate
// 					}
// 					style={{cursor : 'pointer'}}
// 				>
// 					{edit ? 'subdirectory_arrow_left' : ''}
// 				</IconCustom> */}
// 			</div>
// 		</FormContainer>
// 	);
// }

// function EditingTagVideo({
// 	tagColor,
// 	addTagButtonColor = 'var(--greyBorder)',
// 	AddTagButton,
// 	singleValueStyle,
// 	selectedSideTab,
// 	Options,
// 	tagList,
// 	clipId,
// 	projectId,
// 	selectedRespondantVideoId,
// 	captureData,
// 	handleUpdate,
// 	respondantInfo,
// 	CLIP_ID

// }) {
// 	const [tagsList, setTagsList] = useState([]);
// 	//console.log("taglist", tagsList)
// 	const [showResults, setShowResults] = React.useState(false)
// 	const handleAddtag = () => {
// 		setTagsList((prev) => [...prev, { label: '', value: '' }]);
// 		setShowResults(true);
// 		setShowResults(false);
// 	};
// 	const dispatch = useDispatch();

// 	const loggedUser = useSelector(state => state.LoginReducer);
// 	const captureguideData = useSelector(state => state.GetCaptureGuideReducer.captureguideData);
// 	//console.log("CaptureGuideData", captureguideData)

// 	// const parsedJsonData = useSelector(state => state.FieldPreEditorReducer.preEditorData?.CaptureGuide[0]?.clip_array);
// 	// useEffect(() => {
// 	// 	if (parsedJsonData) {
// 	// 		const clipData = parsedJsonData.find(item => item.clip_ID === clipId);
// 	// 		if (clipData) {
// 	// 			setTranscript(clipData.transcript);
// 	// 		}
// 	// 	}
// 	// }, [parsedJsonData, clipId]);

// 	const parsedJsonData = useSelector(state => {
// 		const captureGuide = state.FieldPreEditorReducer.preEditorData?.CaptureGuide;
// 		if (captureGuide) {
// 			return captureGuide.map(item => item.clip_array).flat();
// 		}
// 		return [];
// 	});

// 	useEffect(() => {
// 		if (parsedJsonData) {
// 			const clipData = parsedJsonData.find(item => item.clip_ID === clipId);
// 			if (clipData) {
// 				setTranscript(clipData.transcript);
// 			}
// 		}
// 	}, [parsedJsonData, clipId]);

// 	const [transcript, setTranscript] = useState('');
// 	//console.log("New Transcript", transcript)
// 	const [editMode, setEditMode] = useState(false);

// 	const handleEditAllClick = () => {
// 		setEditMode(true);
// 	};

// 	useEffect(() => {
// 		console.log("In useeffect hook", transcript)
// 		setTranscript(transcript)
// 	}, [transcript]);
// 	// function separateTranscript(transcript) {
// 	// 	const speakerSegments = {};
// 	// 	const segments = transcript.split(/[.!?]/);
// 	// 	segments.forEach(segment => {
// 	// 		const colonIndex = segment.indexOf(':');
// 	// 		if (colonIndex !== -1) {
// 	// 			const speaker = segment.substring(0, colonIndex).trim();
// 	// 			const speech = segment.substring(colonIndex + 1).trim();
// 	// 			if (speaker in speakerSegments) {
// 	// 				speakerSegments[speaker].push(speech);
// 	// 			} else {
// 	// 				speakerSegments[speaker] = [speech];
// 	// 			}
// 	// 		}
// 	// 	});

// 	// 	return speakerSegments;
// 	// }

// 	function separateTranscript(transcript) {

// 		let speakerSegments = {};

// 		const segments = transcript.split(/(\w+:)/);

// 		segments.forEach((segment, index) => {

// 			const cleanSegment = segment.trim();

// 			if (cleanSegment.endsWith(':') && segments[index + 1]) {

// 				const speaker = cleanSegment.slice(0, -1);

// 				if (!speakerSegments[speaker]) {
// 					speakerSegments[speaker] = [];
// 				}

// 				speakerSegments[speaker].push(segments[index + 1].trim());
// 			}
// 		});

// 		return speakerSegments;
// 	}

// 	const speakerSegments = separateTranscript(transcript);
// 	console.log(speakerSegments);

// // useEffect(() => {
// //     const segments = separateTranscript(transcript);
// //     setSpeakerSegments(segments);
// // }, [transcript]);

// 	const handleSaveAllEdit = () => {
// 		console.log("NewTranscript", transcript)
// 		//setTranscript(newTranscript)
// 		dispatch({
// 			type: UPDATE_EDITOR_TRANSCRIPT_DATA,
// 			payload: {
// 				"user_ID": loggedUser.userId,
// 				"clip_ID": clipId,
// 				"transcript": transcript
// 			}
// 		});

// 		setTimeout(() => {
// 			dispatch({
// 				type: 'REQUEST_PRE_EDITOR_DATA',
// 				payload: {
// 					user_ID: loggedUser.userId,
// 					project_ID: projectId,
// 					projectRespondent_ID: selectedRespondantVideoId
// 				}
// 			});
// 		}, 500);

// 		setEditMode(false);
// 	};

// 	const handleTextChange = (e) => {
// 		const newTranscriptValue = e.target.value;
// 		console.log("New Transcript Value:", newTranscriptValue);
// 		setTranscript(newTranscriptValue);
// 	//	console.log("Updated Transcript:", transcript);
// 	};

// 	useEffect(() => {
// 		setTagsList(tagList)
// 	}, [tagList])

// 	useEffect(() => {
// 		// console.log('into capturedata', captureData)
// 	}, [tagList])

// 	// const removeTag = (tag_id) => {
// 	// 	let index = tagsList.findIndex(tag => tag.tag_ID === tag_id);
// 	// 	let tempTagsList = [...tagsList];
// 	// 	tempTagsList.splice(index, 1);
// 	// 	delete tempTagsList[index];
// 	// 	setTagsList(tempTagsList);
// 	// 	console.log("delete tag")
// 	// }
// 	const removeTag = (tag_id) => {
// 		let index = tagsList.findIndex(tag => tag.tag_ID === tag_id);
// 		if (index !== -1) {
// 			let tempTagsList = [...tagsList];
// 			tempTagsList.splice(index, 1);
// 			//delete tempTagsList[index];
// 			setTagsList(tempTagsList);
// 			console.log("delete tag");
// 		} else {
// 			console.log("Tag Not Found");
// 		}
// 		console.log("setShowResults", showResults);
// 	}

// 	const handleUpdateClick = () => {
// 		handleUpdate();
// 	}
// 	return (
// 		<div className='d-flex align-items-center flex-wrap'>
// 			{tagsList?.map((item, index) => (
// 				<Input
// 					submit={(text) => console.log(text)}
// 					key={index}
// 					className='mr-2 mb-2'
// 					Options={Options}
// 					value={item}
// 					color={tagColor}
// 					singleValueStyle={singleValueStyle}
// 					clipId={clipId}
// 					tagDeleted={removeTag}
// 					projectId={projectId}
// 					selectedRespondantVideoId={selectedRespondantVideoId}
// 					tagsList={tagsList}
// 					handleUpdate={handleUpdate}
// 					selectedSideTab={selectedSideTab}
// 					setShowResults={setShowResults}
// 					onChange={(e) => setTag}
// 				/>
// 			))

// 			}
// 			<div
// 				className='d-flex align-items-center mb-2 '
// 				onClick={handleAddtag}
// 			>
// 				{!showResults ? AddTagButton ? (
// 					<AddTagButton />
// 				) : (
// 					<>
// 						<Icon
// 							style={{ fontSize: '16px' }}
// 							color={addTagButtonColor}
// 						>
// 							add
// 						</Icon>{' '}
// 						<Heading
// 							fontSize='10px'
// 							fontWeight='500'
// 							color={addTagButtonColor}
// 							className='mb-0 pb-0 ml-1'
// 						>
// 							Add Tag{' '}
// 						</Heading>
// 					</>
// 				) : ""}

// 			</div>

// 			<div className="d-flex align-items-center flex-wrap">
// 				<CustomButton
// 					className="ml-2"
// 					size="small"
// 					variant="outlined"
// 					buttonColor="var(--sapGreen)"
// 					onClick={editMode ? handleSaveAllEdit : handleEditAllClick}
// 				>
// 					{editMode ? 'Save Transcript' : 'Edit'}
// 				</CustomButton>
// 			</div>
// 			<div className="d-flex align-items-center flex-wrap">
// 				<div style={{ position: 'relative' }}>
// 					<div style={{ width: '300px', height: '600px', overflow: 'auto', color: 'white' }}>
// 						<div className="d-flex mt-2 w-100 border-bottom" style={{ fontSize: '14px' }}>
// 							{editMode ? (
// 								<CustomTextArea
// 								value={transcript}
// 								showCharacters={false}
// 								className="mt-1"
// 								width={'800px'}
// 								height={'200px'}
// 								color="white"
// 								onChange={handleTextChange}
// 								/>
// 							) : (
// 								<div className="p-2 w-100" style={{ color: 'white' }}>
// 									{transcript}
// 									{/* {Object.entries(speakerSegments).map(([speaker, speeches], index) => (
// 										<div key={index}>
// 											<p><strong>{speaker}:</strong></p>
// 											{speeches.map((speech, index) => (
// 												<p key={index}>{speech}</p>
// 											))}
// 										</div>
// 									))} */}

// 								</div>
// 							)}
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</div>

// 	)
// }

// const mapStateToProps = (state) => {
// 	return {
// 		// will be available as props.captureData
// 		captureData: state.FieldPreEditorReducer.preEditorData
// 	}
// }

// export default connect(mapStateToProps)(EditingTagVideo);

import styled from '@emotion/styled';
import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Heading, Icon, Button, ActionIcon } from '../../common';
import { TextArea } from '../../components/form';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import SearchableDropdown from '../../components/form/SearchableDropdown';
import Dropdown from '../../components/form/TaskTypeOption';
import { REQUEST_PRE_EDITOR_DATA } from '../../constants/field/FieldPreEditorConstant';
import { UPDATE_RTM_TRANSCRIPT_DATA } from '../../constants/projects/ProjectConstants';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { globalStartTime, globalEndTime } from './VideoTimeline';

const CustomButton = styled(Button)`
  width: ${props => props.width};
  font-size: 12px;
  padding: 0.2rem 0.5rem !important;
`;

const CustomTextArea = styled(TextArea)`
  ::placeholder,
  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  :-ms-input-placeholder {
    color: white;
    font-weight: 400;
    font-size: 10px;
  }
`;
const TextInput = styled.input`
  border: 0;
  background: transparent;
  resize: none;
  font-size: 11px;
  font-weight: 500;
  color: red;
  width: 100%;
  margin: 0;
  padding: 0;

  &:focus-visible {
    border: 0;

    outline: none;
  }
`;

const IconCustom = styled(Icon)`
  font-size: 0.75rem;
  // margin-top: 7px;
`;

const FormContainer = styled.div`
  display: flex;
  width: auto;
  padding: 0 8px;
  margin: 0;
  border: ${props => (props.color ? `1px solid ${props.color}` : `1px solid var(--greyBorder)`)};
  border-radius: 20px;
  .css-tlfecz-indicatorContainer {
    display: none;
  }
  input {
    font-size: 11px;
    font-weight: 500;
    color: ${props => (props.color ? `${props.color} ` : `var(--white)`)};

    color: red;
  }
  span {
    align-self: flex-end;
    margin-bottom: 0.5rem;
  }
`;

const customeStylesField = {
  container: (provided, state) => ({
    ...provided,
    border: 0,
    borderRadius: 0
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    ...state.selectProps.valueContainerStyle
  }),

  control: (provided, state) => ({
    ...provided,
    outline: 0,
    borderRadius: 0,
    border: 0,
    background: 'transparent',
    // border: '1px solid var(--white)',
    // borderRadius: '20px',
    borderColor: state.isFocused ? 'var(--inputColor)' : 'var(--borderColor)',
    boxShadow: 'none',
    minHeight: '30px',
    '&:hover': {
      borderColor: state.isFocused ? 'var(--inputColor)' : 'var(--borderColor)'
    },
    ...state.selectProps.controlStyle
  }),

  menu: (provided, state) => ({
    ...provided,

    borderRadius: '5px',
    width: 'auto',
    padding: '0 10px',
    // position: 'absolute',
    background: '#8A8A8A',
    // width: '220px',
    zIndex: 5,
    boxShadow: 'none',
    textAlign: 'left'
  }),

  option: (provided, state) => ({
    fontSize: '0.75rem',
    fontWeight: '500',
    color: 'var(--white)',
    padding: '0px 3px',
    textAlign: 'left',
    cursor: 'pointer',

    '&:last-child': {
      borderBottom: 0
    }
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '1px',
    fontSize: '11px',
    fontWeight: '500',
    color: 'var(--white)',
    ...state.selectProps.inputStyle
  }),

  singleValue: (provided, state) => ({
    fontSize: state.selectProps.selectedFontSize ? state.selectProps.selectedFontSize : '11px',
    fontWeight: state.selectProps.selectedFontWeight ? state.selectProps.selectedFontWeight : '500',
    color: state.selectProps.selectedColor ? state.selectProps.selectedColor : 'var(--white)',

    ...state.selectProps.singleValueStyle
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),
  IndicatorsContainer: () => ({
    display: 'none'
  }),
  placeholder: () => ({
    fontSize: '0.75rem',
    color: 'var(--mediumGrey)'
  })

  // menu: (provided, state) => ({
  //   ...provided,
  //   width: state.selectProps.width,
  //   borderBottom: '1px dotted pink',
  //   color: state.selectProps.menuColor,
  //   padding: 20,
  // }),
};

// function InputForCreate({ className, submit, Options, value='', color, singleValueStyle, clipId, tagDeleted, projectId, selectedRespondantVideoId, tagsList, setTagsList, clipDetails, setSelectedGuideNotes, selectedSideTab, setShowResults}){
// 	const { projectRepondentVideo_ID } = useSelector(state => state.GetCaptureGuideReducer.captureguideData);
// 	const formRef = useRef(null);
// 	const loggedUser = useSelector(state => state.LoginReducer);

// 	const dispatch = useDispatch();

// 	const [text, setText] = useState({"value": value.tag_ID, "label": value.tagName});
// 	const [edit, setEdit] = useState(false);

// 	useEffect(() => {
// 		setText({"value": value.tag_ID, "label": value.tagName});
// 	}, [tagsList])

// 	const handleSubmitForCreate = (e) => {
// 		e.preventDefault();
// 		submit(text);
// 		setEdit(true);

// 	};

// 	useEffect(() =>{
// 		const handleOutsideForm = (e) =>{
// 			if(formRef.current && !formRef.current.contains(e.target)){
// 				setEdit(false);
// 			}
// 		};

// 		document.addEventListener('click',handleOutsideForm);

// 		return() =>{
// 			document.removeEventListener('click',handleOutsideForm);
// 		}
// 	},[]);

// 	const deleteTagForCreate = (tag_id) => {
// 		// tagDeleted(tag_id) ;
// 		// console.log("tagsList in delete",tagsList)
// 		// const updatedGuideNotes = {...clipDetails, Tag: tagsList}
// 		// setSelectedGuideNotes(updatedGuideNotes);
// 		let index = tagsList.findIndex(tag => tag.tag_ID === tag_id);
// 		if(index !== -1){
// 			let tempTagsList = [...tagsList];
// 			tempTagsList.splice(index, 1);
// 			//delete tempTagsList[index];
// 			setTagsList(tempTagsList);
// 			const updatedGuideNotes = {...clipDetails, Tag: tempTagsList}
// 			setSelectedGuideNotes(updatedGuideNotes)

// 		} else{
// 			console.log("tag not found")
// 		}
// 		// setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
// 		// 	() => getUpdatedPreEditorDataForCreate(),
// 		// 	500
// 		//   );
// 		//   setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
// 		//   () => {
// 		// 	  document.getElementById(selectedSideTab)?.click();
// 		// 	  submit(text);
// 		// 	  setShowResults(false);
// 		//   },
// 		//   500
// 		// );
// 	}

// 	const updateTagForCreate = (tag_id,newTagValue) =>{

// 		const updatedTags = tagsList.map(tag => {
// 			if(tag.tag_ID === ''){
// 				return {...tag, tag_ID: tag_id, tagName: newTagValue}
// 			}
// 			// if(tag.value === tag_id){
// 			// 	return {...tag, label: newTagValue }
// 			// }
// 			else return tag
// 		})

// 		setTagsList(updatedTags) ;
// 		const updatedGuideNotes = {...clipDetails, Tag: updatedTags}
// 		setSelectedGuideNotes(updatedGuideNotes);

// 		// setTimeout(()=>{
// 		// 	getUpdatedPreEditorDataForCreate();
// 		// 	setEdit(false);

// 		// },500)
// 		// setTimeout(() =>{
// 		// 	document.getElementById(selectedSideTab)?.click();
// 		// 	setShowResults(false);
// 		// 	setEdit(false);
// 		// },1000)
// 	}

// 	const handleDropdownChangeForCreate = (opt) =>{
// 		setEdit(true);
// 		const newTagValue = opt.label;
// 		updateTagForCreate(opt.value,newTagValue);
// 	}

// 	const onClickInputForCreate = () =>{
// 		console.log("Input clicked");
// 		setEdit(true);
// 	}

// 	const getUpdatedPreEditorDataForCreate = () => {
// 		dispatch({
// 			type: 'REQUEST_PRE_EDITOR_DATA',
// 			payload: {
// 				user_ID: loggedUser.userId,
// 				project_ID: projectId,
// 				projectRespondent_ID: selectedRespondantVideoId
// 			}
// 		});
// 	};
// 	return (
// 		<FormContainer ref={formRef} color={color} className={className} disable={!edit}>
// 			<form onSubmit={handleSubmitForCreate} onClick={onClickInputForCreate} >
// 				<SearchableDropdown
// 					isSearchable
// 					singleValueStyle={singleValueStyle}
// 					valueContainerStyle={{
// 						padding: edit ? '0 20px 0 0px' : '0 10px 0 0px',
// 					}}
// 					controlStyle={{ minHeight: 'auto' }}
// 					inputStyle={{
// 						color: color,
// 					}}
// 					selectedColor={color}
// 					defaultValue={text}
// 					options={Options}
// 					onChange={handleDropdownChangeForCreate}
// 				/>
// 				{/* <button onClick={handleUpdate}>Update tag</button> */}

// 			</form>

// 			<div style={{ height: '12px' }}>
// 				<IconCustom

// 					color={color}
// 					onClick={(e)=>{
// 						deleteTagForCreate(text.value)
// 					}}
// 					style={{cursor : 'pointer'}}
// 				>
// 					{/* {'close'} */}
// 					{!edit ? 'close' : 'subdirectory_arrow_left'}
// 				</IconCustom>
// 			</div>
// 		</FormContainer>
// 	);
// }

function Input({
  className,
  submit,
  Options,
  value = '',
  color,
  singleValueStyle,
  clipId,
  tagDeleted,
  projectId,
  selectedRespondantVideoId,
  tagsList,
  setTagsList,
  clipDetails,
  setSelectedGuideNotes,
  selectedSideTab,
  setShowResults
}) {
  // console.log("value",value)
  const { projectRepondentVideo_ID } = useSelector(state => state.GetCaptureGuideReducer.captureguideData);
  const formRef = useRef(null);
  const loggedUser = useSelector(state => state.LoginReducer);
  //const[isConfirmationOpen,setIsConfirmationOpen] = useState(false);
  //const { projectId } = useParams();
  let location = useLocation();
  //console.log("loaction state in video tag : ", location.state)
  const dispatch = useDispatch();

  const [text, setText] = useState({ value: value.tag_ID, label: value.tagName });
  const [masterTagList, setMasterTagList] = useState(Options);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setText({ value: value.tag_ID, label: value.tagName });
  }, [tagsList]);

  const handleSubmit = e => {
    e.preventDefault();
    submit(text);
    setEdit(true);
    //saveTag(text.label);
    setMenuIsOpen(false);
  };

  useEffect(() => {
    const handleOutsideForm = e => {
      if (formRef.current && !formRef.current.contains(e.target)) {
        setEdit(false);
      }
    };

    document.addEventListener('click', handleOutsideForm);

    return () => {
      document.removeEventListener('click', handleOutsideForm);
    };
  }, []);

  const handleDeleteDispatch = id => {
    let payload = {
      user_ID: loggedUser.userId,
      id: id
    };
    dispatch({ type: 'REQUEST_DELETEFORMTAG', payload: payload });
    // setTimeout(() => {
    // 	dispatch({
    // 		type: 'REQUEST_GET_VIDEO_TASK' ,
    // 		payload: {
    // 			user_ID: userId,
    // 			project_ID: projectId,
    // 			projectTask_ID: projectTask_ID
    // 		}
    // 	});
    // }, 800);
  };

  const saveTag = tag => {
    let payload = {
      user_ID: loggedUser.userId,
      clip_ID: clipId,
      projectTagName: tag,
      project_ID: projectId,
      projectRepondentVideo_ID: projectRepondentVideo_ID
    };
    dispatch({ type: 'REQUEST_ADDVIDEOTAG', payload: payload });
    setTimeout(
      /*Here it is in settimeout because it takes sometime to update values and the fetch*/
      () => {
        getUpdatedPreEditorData();
        setText({ value: value.tag_ID, label: value.tagName });
        submit(text);
      },
      500
    );
    setTimeout(
      /*Here it is in settimeout because it takes sometime to update values and the fetch*/
      () => {
        document.getElementById(selectedSideTab).click();
        setEdit(false);
        setShowResults(false);
        submit(text);
      },
      3000
    );
  };
  //for update
  const deleteTag = tag_id => {
    // const isConfirmed = window.confirm("Are you sure wanted to delete or update this tag?");
    // if(isConfirmed){
    // 	let payload = {
    // 	"user_ID": loggedUser.userId,
    // 	"tag_ID": tag_id
    // }
    // dispatch({ type: 'REQUEST_DELETEVIDEOTAG', payload: payload });
    // tagDeleted(tag_id);
    // setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
    // 	() => getUpdatedPreEditorData(),
    // 	500
    //   );
    //   setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
    //   () => {
    // 	  document.getElementById(selectedSideTab).click();
    // 	  submit(text);
    // 	  setShowResults(false);
    //   },
    //   3000
    // );
    // }else{
    // 	alert('Deletion or updation of tag is cancel by user')
    // }
    let payload = {
      user_ID: loggedUser.userId,
      tag_ID: tag_id
    };

    tagDeleted(tag_id);
    if (clipId !== 0) {
      dispatch({ type: 'REQUEST_DELETEVIDEOTAG', payload: payload });
      setTimeout(
        /*Here it is in settimeout because it takes sometime to update values and the fetch*/
        () => getUpdatedPreEditorData(),
        500
      );
      setTimeout(
        /*Here it is in settimeout because it takes sometime to update values and the fetch*/
        () => {
          document.getElementById(selectedSideTab).click();
          submit(text);
          setShowResults(false);
        },
        3000
      );
    }
  };

  const handleUpdate = () => {
    const newTagValue = window.prompt('Enter the updated tag:', text.label);

    if (newTagValue !== null) {
      updateTag(text.value, newTagValue);
    }
  };

  const updateTag = (tag_id, newTagValue) => {
    const deletePayload = {
      user_ID: loggedUser.userId,
      tag_ID: tag_id
    };

    dispatch({ type: 'REQUEST_DELETEVIDEOTAG', payload: deletePayload });

    // setTimeout(() =>{
    const addPayload = {
      user_ID: loggedUser.userId,
      clip_ID: clipId,
      projectTagName: newTagValue,
      project_ID: projectId,
      projectRespondentVideo_ID: projectRepondentVideo_ID,
      tag_ID: tag_id
    };
    dispatch({ type: 'REQUEST_ADDVIDEOTAG', payload: addPayload });

    setTimeout(() => {
      getUpdatedPreEditorData();
      setEdit(false);
    }, 500);
    setTimeout(() => {
      document.getElementById(selectedSideTab)?.click();
      setShowResults(false);
      setEdit(false);
    }, 1000);
    // },500)
  };

  const handleDropdownChange = opt => {
    setEdit(true);
    const newTagValue = opt.label;
    updateTag(text.value, newTagValue);
  };

  const onClickInput = () => {
    setEdit(true);
  };

  const getUpdatedPreEditorData = () => {
    dispatch({
      type: 'REQUEST_PRE_EDITOR_DATA',
      payload: {
        user_ID: loggedUser.userId,
        project_ID: projectId,
        projectRespondent_ID: selectedRespondantVideoId
      }
    });
  };
  return (
    <FormContainer ref={formRef} color={color} className={className} disable={!edit}>
      <form onSubmit={handleSubmit} onClick={onClickInput}>
        <SearchableDropdown
          isSearchable
          singleValueStyle={singleValueStyle}
          valueContainerStyle={{
            padding: edit ? '0 20px 0 0px' : '0 10px 0 0px'
          }}
          controlStyle={{ minHeight: 'auto' }}
          inputStyle={{
            color: color
          }}
          selectedColor={color}
          defaultValue={text}
          options={Options}
          // onChange={(opt) => {
          // 	//setText(opt);
          // 	setEdit(true);
          // 	saveTag(opt.label);
          // 	submit(text);
          // }}
          masterTagList={masterTagList}
          setMasterTagList={setMasterTagList}
          onChange={handleDropdownChange}
          menuIsOpen={menuIsOpen}
          setMenuIsOpen={setMenuIsOpen}
          crossOption={false}
          passHandleDeleteDispatch={handleDeleteDispatch}
          customStylesForDropdown={customeStylesField}
        />
        {/* <button onClick={handleUpdate}>Update tag</button> */}
      </form>

      <div style={{ height: '12px' }}>
        <IconCustom
          color={color}
          onClick={e => {
            deleteTag(text.value);
          }}
          style={{ cursor: 'pointer' }}
        >
          {/* {'close'} */}
          {!edit ? 'close' : 'subdirectory_arrow_left'}
        </IconCustom>
        {/* <IconCustom 
				
					color={color} 
					onClick={
						handleUpdate
					}
					style={{cursor : 'pointer'}}
				>
					{edit ? 'subdirectory_arrow_left' : ''}
				</IconCustom> */}
      </div>
    </FormContainer>
  );
}

function EditingTagVideo({
  tagColor,
  addTagButtonColor = 'var(--greyBorder)',
  AddTagButton,
  singleValueStyle,
  selectedSideTab,
  Options,
  tagList,
  clipDetails,
  setSelectedGuideNotes,
  clipId,
  projectId,
  selectedRespondantVideoId,
  captureData,
  handleUpdate,
  respondantInfo,
  props
}) {
  const [tagsList, setTagsList] = useState([]);
  const [showResults, setShowResults] = React.useState(false);
  const handleAddtag = () => {
    setTagsList(prev => [...prev, { tag_ID: '', tagName: '' }]);
    setShowResults(true);
    setShowResults(false);
  };

  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const transcriptData = useSelector(state => state.ProjectReducer?.rtmTranscriptData);
  const [parsedJsonData, setParsedJsonData] = useState([]);
  const [editedTexts, setEditedTexts] = useState(Array(parsedJsonData.length).fill(''));
  const [transcriptDownloadStatus, setTranscriptDownloadStatus] = useState(false);
  const [transcriptProjectId, setTranscriptProjectId] = useState(false);
  const doc = new jsPDF();

  // const videostarttime  = localStorage.getItem('videostarttime');
  // console.log("VideoStartTime",videostarttime)
  // const location = useLocation();
  // console.log("IN TAG VIDEO",location.state)

  //console.log("IN EditingTagVideo for props",respondantInfo)

  useEffect(() => {
    if (respondantInfo) {
      const transcriptPayload = {
        user_ID: localStorage.getItem('userId'),
        InterviewSchedule_ID: respondantInfo.InterviewSchedule_ID,
        channel_name: respondantInfo.agoraChannelName,
        transcript_data: {}
      };
   
      dispatch({ type: 'GET_RTM_TRANSCRIPT_DATA', payload: transcriptPayload });
    }
  }, [respondantInfo]);

  useEffect(() => {


    if (transcriptData?.Status?.StatusCode == 1) {
      const parsedData = JSON.parse(transcriptData?.Response.json_data);
      
      setParsedJsonData(parsedData);
      setEditedTexts(parsedData.map(item => item.text));
    }
  }, [transcriptData]);

  const downloadTranscript = async => {
    const transcriptPayload = {
      user_ID: localStorage.getItem('userId'),
      InterviewSchedule_ID: respondantInfo.InterviewSchedule_ID,
      channel_name: respondantInfo.agoraChannelName,
      transcript_data: {}
    };

    setTranscriptProjectId(respondantInfo.InterviewSchedule_ID);
    setTranscriptDownloadStatus(true);
    dispatch({ type: 'GET_RTM_TRANSCRIPT_DATA', payload: transcriptPayload });
  };
  useEffect(() => {
    
    if (transcriptDownloadStatus) {
      if (transcriptData?.Status?.StatusCode == 1) {
        const parsedJsonData = JSON.parse(transcriptData?.Response.json_data);
    
        let hasChannel = parsedJsonData[0].hasOwnProperty('name');
        if (hasChannel) {
          doc.text(535, 50, 'Video Transcripted Data ' + parsedJsonData[0].name);
          const columns = ['Transcripted Conversation'];
          var rows = [];
          for (let i = 0; i < parsedJsonData.length; i++) {
            let hasKey = parsedJsonData[i].hasOwnProperty('text');
            if (hasKey) {
              var temp = [parsedJsonData[i].name + ' : ' + parsedJsonData[i].text];
              rows.push(temp);
            }
          }
          doc.autoTable(columns, rows, {
            startY: 20,
            theme: 'grid',
            styles: {
              font: 'times',
              halign: 'left',
              cellPadding: 3.5,
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0]
            },
            headStyles: {
              textColor: [0, 0, 0],
              fontStyle: 'normal',
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              fillColor: [166, 204, 247]
            },
            alternateRowStyles: {
              fillColor: [212, 212, 212],
              textColor: [0, 0, 0],
              lineWidth: 0.5,
              lineColor: [0, 0, 0]
            },
            rowStyles: {
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              alignItems: 'left'
            },
            tableLineColor: [0, 0, 0]
          });
          doc.save(transcriptProjectId + '-transcript-data.pdf');
          setTranscriptDownloadStatus(false);
        } else {
          alert('Transcription not found');
        }
      } else {
        alert('file not found');
      }
    }
  }, [transcriptData]);

  useEffect(() => {
    setTagsList(tagList);
  }, [tagList]);

  useEffect(() => {
    // console.log('into capturedata', captureData)
  }, [tagList]);

  // const removeTag = (tag_id) => {
  // 	let index = tagsList.findIndex(tag => tag.tag_ID === tag_id);
  // 	let tempTagsList = [...tagsList];
  // 	tempTagsList.splice(index, 1);
  // 	delete tempTagsList[index];
  // 	setTagsList(tempTagsList);
  // 	console.log("delete tag")
  // }
  const removeTag = tag_id => {
    let index = tagsList.findIndex(tag => tag.tag_ID === tag_id);
    if (index !== -1) {
      let tempTagsList = [...tagsList];
      tempTagsList.splice(index, 1);
      //delete tempTagsList[index];
      // setTagsList(tempTagsList);
      // if(clipId === 0){
      // 	const updatedGuideNotes = {...clipDetails, Tag: tempTagsList}
      // 	setSelectedGuideNotes(updatedGuideNotes)
      // }
      console.log('delete tag');
    } else {
      console.log('Tag Not Found');
    }
    console.log('setShowResults', showResults);
  };
  // console.log("setShowResults",showResults);
  // const handleUpdateClick = () =>{
  // 	handleUpdate();
  // }
  return (
    <div>
      {/* {
				clipId === 0 ? 
				<div className='d-flex align-items-center flex-wrap'>
					{tagsList?.map((item, index) => (
							<InputForCreate
								submit={(text) => console.log(text)}
								key={index}
								className='mr-2 mb-2'
								Options={Options}
								value={item}
								color={tagColor}
								singleValueStyle={singleValueStyle}
								clipId={clipId}
								tagDeleted={removeTag}
								projectId={projectId}
								selectedRespondantVideoId={selectedRespondantVideoId}
								tagsList={tagsList}
								setTagsList={setTagsList}
								clipDetails={clipDetails}
								setSelectedGuideNotes={setSelectedGuideNotes}
								handleUpdate={handleUpdate}
								selectedSideTab={selectedSideTab}
								setShowResults={setShowResults}
								onChange = {(e) => setTag}
							/>
						))}
					<div
						className='d-flex align-items-center mb-2 '
						onClick={handleAddtag}
					>
						{!showResults ? AddTagButton ? (
							<AddTagButton />
						) : (
							<>
								<Icon
									style={{ fontSize: '16px' }}
									color={addTagButtonColor}
								>
									add
								</Icon>{' '}
								<Heading
									fontSize='10px'
									fontWeight='500'
									color={addTagButtonColor}
									className='mb-0 pb-0 ml-1'
								>
									Add Tag{' '}
								</Heading>
							</>
						) : ""}
					</div>
				</div> 
				:  */}
      <div className="d-flex align-items-center flex-wrap">
        {tagsList?.map((item, index) => (
          <Input
            submit={text => console.log(text)}
            key={index}
            className="mr-2 mb-2"
            Options={Options}
            value={item}
            color={tagColor}
            singleValueStyle={singleValueStyle}
            clipId={clipId}
            tagDeleted={removeTag}
            projectId={projectId}
            selectedRespondantVideoId={selectedRespondantVideoId}
            tagsList={tagsList}
            setTagsList={setTagsList}
            clipDetails={clipDetails}
            setSelectedGuideNotes={setSelectedGuideNotes}
            handleUpdate={handleUpdate}
            selectedSideTab={selectedSideTab}
            setShowResults={setShowResults}
            onChange={e => setTag}
          />
        ))}
        <div className="d-flex align-items-center mb-2 " onClick={handleAddtag}>
          {!showResults ? (
            AddTagButton ? (
              <AddTagButton />
            ) : (
              <>
                <Icon style={{ fontSize: '16px' }} color={addTagButtonColor}>
                  add
                </Icon>{' '}
                <Heading fontSize="10px" fontWeight="500" color={addTagButtonColor} className="mb-0 pb-0 ml-1">
                  Add Tag{' '}
                </Heading>
              </>
            )
          ) : (
            ''
          )}
        </div>
      </div>
      {/* } */}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    // will be available as props.captureData
    captureData: state.FieldPreEditorReducer.preEditorData
  };
};

export default connect(mapStateToProps)(EditingTagVideo);
