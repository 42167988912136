import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Card, Divider, Heading, Icon } from '../../common';
import ErrorText from '../../components/form/ErrorText';
import { InputText } from '../../components/form';
import Accordion from '../../common/Accordion';
import drag from '../../images/drag.svg';
import deleteImg from '../../images/delete.svg';
import VideoEdit from './VideoEdit';
import Breadcurb from '../../common/Breadcurb';
import EditableInput from '../../common/EditableInput';
import useWindowDimensions from '../../common/useWindowDimensions';
import { REQUEST_PRE_EDITOR_DATA } from '../../constants/field/FieldPreEditorConstant';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { postRequest } from '../../utils/helpers/httpHelper';
import { GET_RECORD_TASK_VIDEO } from '../../constants/field/GetRecordTaskVideoConstants';
const moment = extendMoment(Moment);

const LeftSideContainer = styled(Card)`
  background: var(--midWhite);
  padding: 0;
  border-radius: 5px;
`;
const ScrollContainer = styled.div`
  height: 30.5rem;
  overflow-y: scroll;

  width ::-webkit-scrollbar {
    width: 10px;
    margin-right: 10px;
  }

  track ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  Handle ::-webkit-scrollbar-thumb {
    background: var(--scrollbarColor);
  }

  Handle on hover ::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbarColor);
  }
`;
const RightSideHeaderContainer = styled(Card)`
  background: var(--midWhite);
  padding: 0.7rem 1.4rem;

  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: none;

  li {
    margin-right: 0.75rem;
    font-weight: 500;
    font-size: 12px;
    color: var(--greyBlue);
  }
`;

const AccordionItem = ({
  title,
  time,
  clickFunction,
  id,
  questId,
  ItemActive,
  setItemActive,
  preEditorData,
  setPreEditorData,
  setSelectedGuideNotes,
  userId,
  project_ID,
  projectRespondent_ID,
  index,
  clipIndex,
  clipUpdateStatus
}) => {
  const dispatch = useDispatch();
  const getUpdatedPreEditorData = () => {
    dispatch({
      type: 'REQUEST_PRE_EDITOR_DATA',
      payload: {
        user_ID: userId,
        project_ID: project_ID,
        projectRespondent_ID: projectRespondent_ID
      }
    });
  };
  return (
    <div
      onClick={e => {
        clickFunction(title + clipIndex);
        setItemActive(id);
      }}
      className={`d-flex align-items-center accordion_item_container ${id == ItemActive &&
        'item_active'} ${'clipStatus' + clipUpdateStatus}`}
      id={title + clipIndex}
    >
      <div className="pl-2" style={{ width: '10%' }}>
        <img src={drag} />
      </div>
      <li className="accordion_item" style={{ width: '80%' }}>
        <div className="title">{title}</div>
        <div className="time">{time}</div>
      </li>
      <div className="" style={{ width: '10%' }}>
        <img
          src={deleteImg}
          onClick={() => {
            let updatedPreEditorData = preEditorData;
            updatedPreEditorData.CaptureGuide[index].clip_array[clipIndex].buttonName = '';
            setPreEditorData(updatedPreEditorData);
            dispatch({ type: 'REQUEST_DELETEVIDEOCLIP', payload: { user_ID: userId, clip_ID: id, quest_ID: questId } });
            setTimeout(
              /*Here it is in settimeout because it takes sometime to update values and the fetch*/
              () => {
                getUpdatedPreEditorData();
                setSelectedGuideNotes(null);
              },
              500
            );
          }}
        />
      </div>
    </div>
  );
};
function EditTab(props) {
  const [ItemActive, setItemActive] = useState(false);
  const loggedUser = useSelector(state => state.LoginReducer);
  const loggedUserId = loggedUser.userId;
  const addClipResponse = useSelector(state => state.AddVideoTrimReducer.addClipResponse);
  const editClipResponse = useSelector(state => state.EditClipReducer.editClipResponse);
  const { height, width } = useWindowDimensions();
  const [seekTime, setSeekTime] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [enableNoteEdit, setEnableNoteEdit] = useState(true);
  const [selectedGuideNotes, setSelectedGuideNotes] = useState('');
  const [seekEndTime, setSeekEndTime] = useState(null);
  const [selectedField, setSelectedField] = useState({});
  const dispatch = useDispatch();
  const [selectedSideTab, setSelectedSideTab] = useState('');

  const [isCreateClip, setIsCreateClip] = useState(false);
  const [clipName, setClipName] = useState('');
  ///const projectId = useSelector(state => state.ProjectReducer.project_ID);
  const { id } = useParams();
  const projectRespondent_ID =
    localStorage.getItem('projectRespondent_ID') != null ? localStorage.getItem('projectRespondent_ID') : '';
  const projectRepondentVideo_ID =
    localStorage.getItem('projectRepondentVideo_ID') != null ? localStorage.getItem('projectRepondentVideo_ID') : '';
  const [clipNameError, setClipNameError] = useState(null);
  const [errorWhileEdit, setErrorWhileEdit] = useState(null);
  const [clipTimeError, setClipTimeError] = useState(null);
  const [edittabClipTimeErrorForEdit, setEdittabClipTimeErrorForEdit] = useState();
  const [edittabSectionMessage, setEdittabSectionMessage] = useState();
  const [edittabSaveButtonClicked, setEdittabSaveButtonClicked] = useState();
  props.passClipTimeErrorForEdittoPreCallScreen(edittabClipTimeErrorForEdit);
  props.passClipTimeErrortoPrecallScreen(clipTimeError);
  props.passSectionMessagetoPreCallScreen(edittabSectionMessage);
  props.passSaveButtonClickedtoPreCallScreen(edittabSaveButtonClicked);

  let counter = 1;

  const clickOnSide = (startTime, endTime, video, index, sectionData, clipIndex) => {
    setSeekTime(startTime?.split(' ')[1]);
    setSeekEndTime(endTime?.split(' ')[1]);
    video.edit = false;
    video.index = index;
    setSelectedGuideNotes(video);
    setSelectedField({ sectionName: video.buttonName, index: index, clipIndex: clipIndex, clip_ID: video.clip_ID });
    setEnableNoteEdit(true);
    setIsCreateClip(false);
    dispatch({ type: 'CLEAR_DELETEVIDEOCLIP' });
  };

  const updateCaptureGuideText = (buttonName, index, clipIndex, clip_ID) => {
    let errorEdit = handleClipNameErrorWhileEdit(buttonName);
    if (!errorEdit) {
      let updatedPreEditorData = preEditorData;
      updatedPreEditorData.CaptureGuide[index].clip_array[clipIndex].buttonName = buttonName;
      setPreEditorData(updatedPreEditorData);
      dispatch({
        type: 'REQUEST_EDITBUTTONNAME',
        payload: {
          user_ID: loggedUser.userId,
          question_ID: updatedPreEditorData.CaptureGuide[index].clip_array[clipIndex].question_ID,
          buttonName: buttonName,
          clip_ID: clip_ID
        }
      });
      setTimeout(
        /*Here it is in settimeout because it takes sometime to update values and the fetch*/
        () => getUpdatedPreEditorData(),
        500
      );
    }
  };

  const getUpdatedPreEditorData = () => {
    dispatch({
      type: 'REQUEST_PRE_EDITOR_DATA',
      payload: {
        user_ID: loggedUserId,
        project_ID: id,
        projectRespondent_ID: props.selectedRespondantVideoId
      }
    });
  };

  const noteUpdated = (notesObj, videoNote, type) => {
    if (type && type == 'delete') {
      notesObj.videoNote = null;
      setSelectedGuideNotes(notesObj);
    }
    let updatedNoteIndex = preEditorData.CaptureGuide[notesObj.index]?.clip_array.findIndex(
      x => x.clip_ID == notesObj.clip_ID
    );
    if (
      updatedNoteIndex !== -1 &&
      preEditorData.CaptureGuide[notesObj.index]?.clip_array[updatedNoteIndex]?.videoNote !== undefined
    ) {
      preEditorData.CaptureGuide[notesObj.index].clip_array[updatedNoteIndex].videoNote = videoNote;
    }

    // let updatedPreEditorData = preEditorData
    // setPreEditorData(updatedPreEditorData);
  };

  const enableDisableNoteEdit = video => {
    setEnableNoteEdit(!enableNoteEdit);
  };

  /*useEffect(() => {
		dispatch({
		type: 'REQUEST_PRE_EDITOR_DATA',
		payload: {
			user_ID: loggedUser.userId,
			project_ID: id,
			projectRespondent_ID: projectRepondentVideo_ID
		}
		});
	}, [projectRepondentVideo_ID]);*/

  useEffect(() => {}, [seekTime, clickOnSide]);

  useEffect(() => {
    if (addClipResponse?.projectRespondentTrimVideo_ID != null) {
      getUpdatedPreEditorData();
    }
  }, [addClipResponse]);

  useEffect(() => {
    if (editClipResponse?.projectRespondentTrimVideo_ID != null) {
      getUpdatedPreEditorData();
    }
  }, [editClipResponse]);

  const [preEditorData, setPreEditorData] = useState({});
  useEffect(() => {
    setPreEditorData(props.captureData);
  }, [props.captureData]);
  useEffect(() => {
    setPreEditorData({});
    dispatch({
      type: 'REQUEST_PRE_EDITOR_DATA',
      payload: {
        user_ID: loggedUserId,
        project_ID: id,
        projectRespondent_ID: props.selectedRespondantVideoId
      }
    });
    setTimeout(
      /*Here it is in settimeout because it takes sometime to update values and the fetch*/
      () =>
        dispatch({
          type: GET_RECORD_TASK_VIDEO,
          payload: {
            user_ID: loggedUserId,
            project_ID: props.selectedRespondantVideoId
          }
        }),
      500
    );
  }, [props.selectedRespondantVideoId]);

  useEffect(() => {
    setTimeout(() => {
      setClipTimeError(null);
    }, 3000);
  }, [clipTimeError]);

  useEffect(() => {
    // console.log("clipError in useEffect",clipNameError)
    handleClipNameError(clipName);
  }, [clipName]);

  const handleCreateClip = create => {
    setIsCreateClip(create);
  };

  const handleClipNameErrorWhileEdit = value => {
    if (value == undefined || value == null || value == '') {
      let tempClipNameErrorForEdit = 'Please enter clipname!';
      setErrorWhileEdit(tempClipNameErrorForEdit);
 
      return true;
    }
    setErrorWhileEdit(null);
    return false;
  };

  const handleClipTimeErrorForEdit = cterror => {
    setEdittabClipTimeErrorForEdit(cterror);
  };

  const handleEditTabSectionMessage = smessage => {
    setEdittabSectionMessage(smessage);
  };

  const handleEditTabSaveButtonClicked = savebtn => {
    setEdittabSaveButtonClicked(savebtn);
  };

  const handleClipNameError = value => {
    if (value == undefined || value == null || value == '') {
      let tempClipNameError = 'Please enter clipname!';
      setClipNameError(tempClipNameError);
     
      return true;
    }
    setClipNameError(null);
    return false;
  };

  const handleClipNameChange = e => {
    setClipName(e.target.value);
  };

  const handleClipCreated = (startTime, endTime, sectionId, questionId) => {
    let error = compareTime(startTime, endTime);
    let error1 = handleClipNameError(clipName);
    if (!error && !error1) {
      let payload = {
        user_ID: loggedUser.userId,
        startTime: startTime,
        endTime: endTime,
        questionPaperSection_ID: sectionId,
        question_ID: questionId,
        clipName: clipName,
        projectRepondentVideo_ID: projectRepondentVideo_ID,
        projectRespondent_ID: projectRespondent_ID
        // videoNote: selectedGuideNotes.videoNote,
        // Tag: selectedGuideNotes.Tag
      };

      dispatch({
        type: 'REQUEST_ADDVIDEOTRIM',
        payload: payload
      });

      setTimeout(
        /*Here it is in settimeout because it takes sometime to update values and the fetch*/
        () => getUpdatedPreEditorData(),
        4000
      );
    }
    setClipName('');
  };

  const compareTime = (startTime, endTime) => {
    if (preEditorData && preEditorData?.CaptureGuide) {
      var captureGuide = preEditorData?.CaptureGuide;
      for (var key1 in captureGuide) {
        var clip_array = captureGuide[key1].clip_array;
        for (var key2 in clip_array) {
          var clipStartDate = moment(clip_array[key2].startTime, 'HH:mm:ss', 'Asia/Kolkata');
          var clipEndDate = moment(clip_array[key2].endTime, 'HH:mm:ss', 'Asia/Kolkata');
          var selectedStartDate = moment('0000-00-00 ' + startTime, 'HH:mm:ss', 'Asia/Kolkata');
          var selectedEndDate = moment('0000-00-00 ' + endTime, 'HH:mm:ss', 'Asia/Kolkata');

          var clipDateRange = moment.range(clipStartDate, clipEndDate);
          var selectedDateRange = moment.range(selectedStartDate, selectedEndDate);

          // has overlapping
          if (
            selectedStartDate.within(clipDateRange) ||
            selectedEndDate.within(clipDateRange) ||
            clipStartDate.within(selectedDateRange) ||
            clipEndDate.within(selectedDateRange)
          ) {
            console.log('time range 1 is conflict with time range 2 and vice versa');
            setClipTimeError('Time range error');
            return true;
          }
        }
      }
    }
    setClipTimeError(null);
    return false;
  };

  return (
    <div className="row px-3 py-2">
      <div className="col-md-3">
        <LeftSideContainer>
          <Heading
            type={3}
            className="p-3 mb-0 pb-0"
            fontSize="10px"
            fontWeight="400"
            color="var(--seaBlue)"
            style={{
              lineHeight: '14px',
              letterSpacing: '0.15rem',
              textTransform: 'uppercase'
            }}
          >
            capture guide
          </Heading>
          <Divider background="var(--lightGrey)" height="2px" width="100%" margin="0" />
          <ScrollContainer>
            {preEditorData &&
              preEditorData.CaptureGuide &&
              preEditorData.CaptureGuide.map((data, index) => {
                return (
                  <>
                    <Accordion className=" py-2 px-2" title={data.sectionName}>
                      <ul>
                        {data?.clip_array &&
                          data.clip_array.map((value, clipIndex) => (
                            <AccordionItem
                              title={value.buttonName}
                              time={value.startTime?.split(' ')[1]}
                              id={value.clip_ID}
                              questId={value.question_ID}
                              ItemActive={ItemActive}
                              setItemActive={setItemActive}
                              index={index}
                              clipIndex={clipIndex}
                              preEditorData={preEditorData}
                              setPreEditorData={setPreEditorData}
                              setSelectedGuideNotes={setSelectedGuideNotes}
                              clickFunction={e => {
                                setSelectedSideTab(e);
                                clickOnSide(value.startTime, value.endTime, value, index, data, clipIndex);
                               
                              }}
                              userId={loggedUser.userId}
                              project_ID={id}
                              projectRespondent_ID={props.selectedRespondantVideoId}
                              clipUpdateStatus={value.ClipUpdateStatus}
                            />
                          ))}
                      </ul>
                    </Accordion>
                    <Divider background="var(--lightGrey)" height="2px" width="100%" margin="0" />
                  </>
                );
              })}
          </ScrollContainer>
        </LeftSideContainer>
      </div>
      <div className="col-md-9">
        <RightSideHeaderContainer>
          <Breadcurb>
            <li>Field Overview</li>
            <li>
              <Icon style={{ fontSize: 10 }}>arrow_forward_ios</Icon>
            </li>
            <li className="" aria-current="page">
              Respondent Details
            </li>
            <li>
              <Icon style={{ fontSize: 10 }}>arrow_forward_ios</Icon>
            </li>
            <li>
              {isCreateClip ? (
                <InputText
                  showCharacters={false}
                  value={clipName}
                  error={clipNameError != null && clipNameError}
                  labelText="Clip Name"
                  onChange={e => handleClipNameChange(e)}
                />
              ) : (
                <>
                  <EditableInput
                    value={selectedField.sectionName}
                    submit={text => {
                      updateCaptureGuideText(text, selectedField.index, selectedField.clipIndex, selectedField.clip_ID);
                    }}
                  />
                  {errorWhileEdit && <ErrorText>{errorWhileEdit}</ErrorText>}
                </>
              )}
            </li>
          </Breadcurb>
        </RightSideHeaderContainer>
        <VideoEdit
          selectedSideTab={selectedSideTab}
          moveSeekTo={seekTime}
          endTime={seekEndTime}
          playing={playing}
          setPlaying={setPlaying}
          selectedGuideNotes={selectedGuideNotes}
          setSelectedGuideNotes={setSelectedGuideNotes}
          captureGuideTimes={
            preEditorData?.CaptureGuide ? preEditorData.CaptureGuide.map(guide => guide.clip_array.map(obj => obj)) : []
          }
          tagList={preEditorData && preEditorData.MasterTags ? preEditorData.MasterTags : []}
          enableNoteEdit={enableNoteEdit}
          clipName={clipName}
          setClipNameError={setClipNameError}
          setClipName={setClipName}
          handleClipNameError={handleClipNameError}
          createClip={handleCreateClip}
          isCreateClip={isCreateClip}
          clipCreated={handleClipCreated}
          enableDisableNoteEdit={enableDisableNoteEdit}
          noteUpdated={noteUpdated}
          projectRepondentVideo_ID={projectRepondentVideo_ID}
          loggedUserId={loggedUserId}
          projectRespondent_ID={projectRespondent_ID}
          selectedRespondantVideoId={props.selectedRespondantVideoId}
          getUpdatedPreEditorData={getUpdatedPreEditorData}
          projectId={id}
          clipTimeError={clipTimeError}
          passClipTimeErrorForEdit={handleClipTimeErrorForEdit}
          passSectionMessage={handleEditTabSectionMessage}
          passSaveButtonClicked={handleEditTabSaveButtonClicked}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    // will be available as props.captureData
    captureData: state.FieldPreEditorReducer.preEditorData
  };
};

// export default EditTab;
export default connect(mapStateToProps)(EditTab);
