import { all, putResolve, takeLatest, call, put } from 'redux-saga/effects';
import actions from '../../actions';
import * as projectMethods from '../../utils/api/project/project';
import { createBrowserHistory } from 'history';
import {
  preProjectData,
  updateProjectTaskData,
  addProjectResearcher,
  addProjectLead,
  addProjectObserver,
  getCriteriaAndBudget,
  updateProjectStage,
  getResolution,
  getParticipant,
  SendParticpantListOnEnd,
  removeVideoCall,
  GetEditSection,
  GetAddSection,
  GetDragQuestion,
  EditScreenerAnswer,
  CountNoParticipant,
  VideoRecording,
  VideoRecordingResource,
  QueryVideoRecording,
  updateLayoutVideoRecording,
  stopVideoRecording,
  Resume,
  Schedulecall,
  updateDuration,
  addProjectNewTask,
  VideoRecordingUserList,
  getUIDDetails,
  VideoRecordingUpdate,
  RtcTokenBuilder,
  saveRtmTranscriptData,
  storeTranscriptLogs,
  getRtmTranscriptData,
  updateRtmTranscriptData,
  updateGuestData,
  updateSidData
} from '../../utils/api/project/project';
import {
  preProjectDataRecieved,
  deleteProjectTaskReceived,
  CriteriaAndBudgetRecieved,
  getResolutionRecieved,
  getParticipantRecieved,
  SendParticpantsListReceivedOnEnd,
  editSectionRecieved,
  AddSectionRecieved,
  DragQuestionRecieved,
  CountNoParticipantRecieved,
  EditScreenerAnswerReceived,
  ResumeRecieved,
  SchedulecallRecieved,
  VideoRecordingRecieved,
  VideoRecordingResourceRecieved,
  QueryVideoRecordingRecieved,
  UpdateLayoutVideoRecordingRecieved,
  StopVideoRecordingRecieved,
  updateDurationRecieved,
  VideoRecordingUserListRecieved,
  getUIDDetailsReceived,
  VideoRecordingUpdateRecieved,
  RtcTokenBuilderRecieved,
  deleteTeamMemberReceived,
  RtmTranscriptLogsReceived,
  RtmTranscriptDataReceived
} from '../../actions/project/index';
import { updateProjectTaskRecieved } from '../../actions/project';

const history = createBrowserHistory({ forceRefresh: true });

function* createProject(payload) {
  const promise = projectMethods.createProject(payload.formData);
  const { res } = yield promise;
  yield putResolve({ type: actions.QuestionActions.CREATE_PROJECT_RECEIVED, data: res.Response });
}
function* editProject(payload) {
  const promise = projectMethods.editProject(payload.formData);
  const { res } = yield promise;
  yield putResolve({ type: actions.QuestionActions.EDIT_PROJECT_RECEIVED, data: res.Response });
}

function* deleteReferral(payload) {
  console.log('payload1', payload);
  const promise = projectMethods.deleteReferral(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.QuestionActions.DELETE_REFERRAL_RECEIVED, data: res.Response });
}

function* getProjectPreData() {
  const promise = preProjectData();
  const { res } = yield promise;
  yield putResolve(preProjectDataRecieved(res.Response));
}

function* deleteProjectTask({ payload }) {
  const promise = projectMethods.deleteProjectTask(payload);
  const { res } = yield promise;
  // yield putResolve(deleteProjectTaskReceived(res.Response));
}

function* deleteProjectImage({ payload }) {
  const promise = projectMethods.deleteProjectImage(payload);
  yield promise;
}

function* updateProjectTask({ payload }) {
  const promise = updateProjectTaskData(payload);
  const { res } = yield promise;
  const newTaskPayload = {
    ...payload,
    projectTask_ID: payload.projectsTask_ID
  };
  yield putResolve(updateProjectTaskRecieved(newTaskPayload));
}

function* createProjectLead({ payload }) {
  const promise = addProjectLead(payload);
  const { res } = yield promise;
}

function* addProjectResearchers({ payload }) {
  const promise = addProjectResearcher(payload);
  const { res } = yield promise;
}

function* addProjectObservers({ payload }) {
  const promise = addProjectObserver(payload);
  const { res } = yield promise;
}

function* getCriteriaAndBudgets({ payload }) {
  const promise = getCriteriaAndBudget(payload);
  let project_ID = payload.project_ID;
  let projectIdentity = payload.projectIdentity;
  const { res } = yield promise;
  let result = { ...res, project_ID, projectIdentity };
  yield putResolve(CriteriaAndBudgetRecieved(result));
  if (res.Status && res.Status.StatusCode == 1) {
    if (payload.render) {
      history.push(`/project/${project_ID}/criteria-and-budget`);
    }
  } else {
    console.log('Criteria and budget no api response');
  }
}

function* updateStage({ payload }) {
  const promise = updateProjectStage(payload);
  const { res } = yield promise;
}

function* getResolutions({ payload }) {
  const promise = getResolution(payload);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve(getResolutionRecieved(result));
  if (res.Status && res.Status.StatusCode == 1) {
    if (payload.render) {
      history.push(`/project/${project_ID}/criteria-and-budget`);
    }
  } else {
    console.log('Criteria and budget no api response');
  }
}

function* getParticipants({ payload }) {
  const promise = getParticipant(payload);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve(getParticipantRecieved(result));

  if (res.Status && res.Status.StatusCode == 1) {
    if (payload.render) {
      // history.push(`/project/${project_ID}/design`);
    }
  } else {
    // history.push(`/project/1/pre-video-call`);
    // console.log("ADD section no api response");
  }
}

function* SendParticpantsListOnEnd({ payload }) {
  const promise = SendParticpantListOnEnd(payload);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve(SendParticpantsListReceivedOnEnd(result));
  if (res.Status && res.Status.StatusCode == 1) {
    if (payload.render) {
      // history.push(`/project/${project_ID}/design`);
    }
  } else {
    // history.push(`/project/1/pre-video-call`);
    // console.log("ADD section no api response");
  }
}

function* removeVideoCalls({ payload }) {
  const promise = removeVideoCall(payload);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve(getResolutionRecieved(result));
  if (res.Status && res.Status.StatusCode == 1) {
    if (payload.render) {
      //  history.push(`/project/${project_ID}/criteria-and-budget`);
    }
  } else {
    console.log('remove video call api no respnse');
  }
}

function* GetEditSections({ payload }) {
  const promise = GetEditSection(payload);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID, data: res };
  // console.log("Response of saga res", res)
  // console.log("Saga Status code", res.Status)
  yield putResolve(editSectionRecieved(result));
  if (res.Status && res.Status.StatusCode == 1) {
    if (payload.render) {
      history.push(`/project/${project_ID}/criteria-and-budget`);
    }
  } else {
    console.log('Edit section no api response');
  }
}

function* updateDurations({ payload }) {
  const promise = updateDuration(payload);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve({
    type: actions.ProjectActions.RECIEVED_UPDATE_DURATION,
    payload: result
  });
}

function* GetAddSections({ payload }) {
  const promise = GetAddSection(payload);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID, data: res };
  // console.log("Response of saga res", res)
  // console.log("Saga Status code", res.Status)

  yield putResolve(AddSectionRecieved(result));

  if (res.Status && res.Status.StatusCode == 1) {
    if (payload.render) {
      // history.push(`/project/${project_ID}/design`);
    }
  } else {
    console.log('ADD section no api response');
  }

  //yield put({type : actions.ProjectActions.CLEAR_ADD_SECTION});
  // console.log("Clear add section in sagas")
}

function* GetDragQuestions({ payload }) {
  const promise = GetDragQuestion(payload);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID, data: res };
  // console.log("Response of saga res", res)
  // console.log("Saga Status code", res.Status)

  yield putResolve(DragQuestionRecieved(result));

  if (res.Status && res.Status.StatusCode == 1) {
    if (payload.render) {
      // history.push(`/project/${project_ID}/design`);
    }
  } else {
    console.log('ADD section no api response');
  }

  //yield put({type : actions.ProjectActions.CLEAR_ADD_SECTION});
  // console.log("Clear add section in sagas")
}

function* EditScreenerAnswers(payload) {
  const promise = projectMethods.EditScreenerAnswer(payload.payload);
  const { res } = yield promise;
  let result = { ...res.Response };
  yield putResolve(EditScreenerAnswerReceived(result));
}

function* editAutomatedScreener(payload) {
  const promise = projectMethods.editAutomatedScreener(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.ProjectActions.EDIT_AUTOMATED_SCREENER_RECEIVED, data: res.Response });
}

function* CountNoParticipants({ payload }) {
  const promise = CountNoParticipant(payload);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve(CountNoParticipantRecieved(result));
  if (res.Status && res.Status.StatusCode == 1) {
    if (payload.render) {
      // history.push(`/project/${project_ID}/design`);
    }
  } else {
    // history.push(`/project/1/pre-video-call`);
    // console.log("ADD section no api response");
  }
}

function* VideoRecordings({ payload, token }) {
  const promise = VideoRecording(payload, token);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve(VideoRecordingRecieved(res));
  const name = window.$resourceId;
  if (res.resourceId) {
  } else {
    console.log('video recording no api response');
  }
}

function* VideoRecordingResources({ payload, token }) {
  const promise = VideoRecordingResource(payload, token);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve(VideoRecordingResourceRecieved(res));
  if (res.resourceId) {
  } else {
    console.log('video recording resource no api response');
  }
}

function* QueryVideoRecordings({ payload, token }) {
  const promise = QueryVideoRecording(payload, token);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve(QueryVideoRecordingRecieved(res));
  if (res.resourceId) {
  } else {
    console.log('query no api response');
  }
}

function* updateLayoutVideoRecordings({ payload, token }) {
  const promise = updateLayoutVideoRecording(payload, token);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };

  yield putResolve(UpdateLayoutVideoRecordingRecieved(res));

  if (res.resourceId) {
  } else {
    console.log('query no api response');
  }
}

function* updateVideoRecording({ payload, token }) {
  const promise = updateLayoutVideoRecording(payload, token);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };

  yield putResolve(UpdateLayoutVideoRecordingRecieved(res));

  if (res.resourceId) {
  } else {
    console.log('query no api response');
  }
}

function* stopVideoRecordings({ payload, token }) {
  const promise = stopVideoRecording(payload, token);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve(StopVideoRecordingRecieved(res));
  if (res.resourceId) {
  } else {
    //console.log("stop video recording no api response");
  }
}

function* ResumeData({ payload }) {
  const promise = Resume(payload);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve(ResumeRecieved(result));
  if (res.Status && res.Status.StatusCode == 1) {
    if (payload.render) {
      // history.push(`/project/${project_ID}/design`);
    }
  } else {
    console.log('Resume Data no api response');
  }
}

function* SchedulecallData({ payload }) {
  const promise = Schedulecall(payload);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve(SchedulecallRecieved(result));
  if (res.Status && res.Status.StatusCode == 1) {
    if (payload.render) {
      // history.push(`/project/${project_ID}/design`);
    }
  } else {
    console.log('Resume Data no api response');
  }
}

function* addNewProjectTask({ payload }) {
  const promise = addProjectNewTask(payload);
  const { res } = yield promise;
  const newTaskPayload = {
    ...payload,
    projectTask_ID: res.Response.projectsTask_ID
  };

  yield putResolve(updateProjectTaskRecieved(newTaskPayload));
}

function* getScheduleDate({ payload }) {
  try {
    const promise = projectMethods.getScheduleDateData(payload);
    const { res } = yield promise;
    const { scheduleDates_data, researcher_data } = res.Response;
    const scheduleDateData = scheduleDates_data.map(item => {
      // item.title = '';
      item.start = item.scheduleStartTime;
      item.end = item.scheduleEndTime;
      item.photo = false;

      const researcherUser_ID = researcher_data.filter(
        researcher => researcher.researcher_ID === item.researcherUser_ID
      );
      item.title = researcherUser_ID[0] ? researcherUser_ID[0].name : '';
      item.researcher_ID = researcherUser_ID[0] ? researcherUser_ID[0].researcher_ID : null;
      return item;
    });

    res.Response.scheduleDates_data = scheduleDateData;
    res.Response.researcher_data = res.Response.researcher_data.map(item => {
      item.label = item.name;
      item.value = item.id;
      return item;
    });
    yield putResolve({ type: actions.ProjectActions.REQUEST_SCHEDULE_DATES_RECEIVED, data: res?.Response });
  } catch (err) {
    console.log('Error:', err);
  }
}

function* addScheduleDate(data) {
  try {
    const { payload } = data;
    const promise = projectMethods.addScheduleDate(payload);
    const { res } = yield promise;
    // yield putResolve({ type: actions.ProjectActions.REQUEST_SCHEDULE_DATES_RECEIVED, data: res.Status });
    // yield putResolve({ type: 'REQUEST_SCHEDULE_DATES' });
    yield putResolve({ type: actions.ProjectActions.ADD_SCHEDULE_DATE_RECEIVED, data: res.Status });
  } catch (err) {
    // Error handling here
  }
}

function* editScheduleDate(data) {
  try {
    const { payload } = data;
    const promise = projectMethods.editScheduleDate(payload);
    const { res } = yield promise;
    yield putResolve({ type: 'REQUEST_SCHEDULE_DATES' });
    yield putResolve({ type: actions.ProjectActions.REQUEST_SCHEDULE_DATES_RECEIVED, data: res.Response });
  } catch (err) {
    // Error handling here
  }
}

function* deleteScheduleDate(data) {
  try {
    const { payload } = data;
    const promise = projectMethods.deleteScheduleDate(payload);
    const { res } = yield promise;
    // yield putResolve({ type: 'REQUEST_SCHEDULE_DATES' });
    yield putResolve({ type: actions.ProjectActions.DELETE_SCHEDULE_DATE_RECEIVED, data: res });
  } catch (err) {
    // Error handling here
  }
}

function* addProjectGroup(data) {
  try {
    const { payload } = data;
    const promise = projectMethods.addProjectGroup(payload);
    const { res } = yield promise;
    yield putResolve({ type: 'PROJECT_GROUP_RECEIVED', data: res });
  } catch (err) {
    // Error handling here
  }
}

function* editProjectGroup(data) {
  try {
    const { payload } = data;
    const promise = projectMethods.editProjectGroup(payload);
    const { res } = yield promise;
    yield putResolve({ type: 'PROJECT_GROUP_RECEIVED', data: res });
  } catch (err) {
    // Error handling here
  }
}

function* deleteProjectGroup(data) {
  try {
    const { payload } = data;
    const promise = projectMethods.deleteProjectGroup(payload);
    const { res } = yield promise;
    yield putResolve({ type: 'PROJECT_GROUP_RECEIVED' });
  } catch (err) {
    // Error handling here
  }
}

function* createBudget(data) {
  try {
    const { payload } = data;
    const promise = projectMethods.createBudget(payload);
    const { res } = yield promise;
    yield putResolve({ type: actions.ProjectActions.SAMPLE_AND_BUDGET_RECEIVED, data: res.Status });
  } catch (err) {}
}

function* assignSampleAndBudget(data) {
  try {
    const { payload } = data;
    const promise = projectMethods.assignSampleAndBudget(payload);
    const { res } = yield promise;
    yield putResolve({ type: 'ASSIGNED_SAMPLE_AND_BUDGET_SUCCESS' });
  } catch (err) {}
}

function* deassignSampleAndBudget(data) {
  try {
    const { payload } = data;
    const promise = projectMethods.deassignSampleAndBudget(payload);
    const { res } = yield promise;
    yield putResolve({ type: 'ASSIGNED_SAMPLE_AND_BUDGET_SUCCESS' });
  } catch (err) {}
}

function* VideoRecordingUserLists({ payload, token }) {
  const promise = VideoRecordingUserList(payload, token);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve(VideoRecordingUserListRecieved(res));
  if (res.resourceId) {
  } else {
    console.log('video recording User list response not');
  }
}

function* getUIDDetailss({ payload }) {
  console.log('inside getUIDDetailss');
  const promise = getUIDDetails(payload);
  const { res } = yield promise;
  yield putResolve(getUIDDetailsReceived(res.Response));
}

function* VideoRecordingUpdates({ payload, token }) {
  const promise = VideoRecordingUpdate(payload, token);
  let project_ID = payload.project_ID;
  const { res } = yield promise;
  let result = { ...res.Response, project_ID };
  yield putResolve(VideoRecordingUpdateRecieved(res));
  if (res.resourceId) {
  } else {
    console.log('video recording update no api response');
  }
}

function* RtcTokenBuilders({ payload }) {
  console.log('saga', payload);
  const promise = RtcTokenBuilder(payload);
  const { res } = yield promise;
  yield putResolve(RtcTokenBuilderRecieved(res));
  if (res.resourceId) {
  } else {
    console.log('video recording update no api response');
  }
}

function* saveRtmTranscriptDatas({ payload }) {


  const promise = saveRtmTranscriptData(payload);
  const { res } = yield promise;
  yield putResolve(RtmTranscriptDataReceived(res));
  console.log('Save Transcript Data Success');
  // if (res.Status.StatusCode) {
  //   console.log('Save Transcript Data Success');
  // } else {
  //   console.log('Save Transcript Data Failed');
  // }
}

function* updateSid({ payload }) {
  const promise = updateSidData(payload);
  const { res } = yield promise;
}

function* storeTranscriptLog({ payload }) {
  const promise = storeTranscriptLogs(payload);
  const { res } = yield promise;
  yield putResolve(RtmTranscriptLogsReceived(res));
  console.log('Save Transcript Logs Success');
}

function* getRtmTranscriptDatas({ payload }) {
  console.log('getRtm saga', payload);

  const promise = getRtmTranscriptData(payload);

  const { res } = yield promise;

  yield putResolve(RtmTranscriptDataReceived(res));

  if (res.Status.StatusCode) {
    console.log('GET Transcript Data Success');
  } else {
    console.log('GET Transcript Data Failed');
  }
}

function* updateRtmTranscriptDatas({ payload }) {

  const promise = updateRtmTranscriptData(payload);
  const { res } = yield promise;
  // yield putResolve(RtmTranscriptDataReceived(res));

}

function* updateGuestDatas({ payload }) {

  const promise = updateGuestData(payload);
  const { res } = yield promise;
  yield putResolve(RtmTranscriptDataReceived(res));

}

function* deleteTeamMember({ payload }) {
  const promise = projectMethods.deleteTeamMember(payload);
  const { res } = yield promise;
}

function* storeTranscriptMessageLog({ payload }) {
  const promise = storeTranscriptMessageLogs(payload);
  const { res } = yield promise;
  yield putResolve(RtmTranscriptMessageLogsReceived(res));
 
}

export {
  createProject,
  editProject,
  deleteReferral,
  getProjectPreData,
  deleteProjectImage,
  deleteProjectTask,
  updateProjectTask,
  createProjectLead,
  addProjectResearchers,
  addProjectObservers,
  getCriteriaAndBudgets,
  updateStage,
  getResolutions,
  getParticipants,
  SendParticpantsListOnEnd,
  removeVideoCalls,
  GetEditSections,
  GetAddSections,
  GetDragQuestions,
  CountNoParticipants,
  EditScreenerAnswers,
  editAutomatedScreener,
  VideoRecordings,
  VideoRecordingResources,
  QueryVideoRecordings,
  updateLayoutVideoRecordings,
  stopVideoRecordings,
  ResumeData,
  SchedulecallData,
  updateDurations,
  addNewProjectTask,
  getScheduleDate,
  addScheduleDate,
  editScheduleDate,
  deleteScheduleDate,
  addProjectGroup,
  editProjectGroup,
  deleteProjectGroup,
  createBudget,
  assignSampleAndBudget,
  deassignSampleAndBudget,
  VideoRecordingUserLists,
  getUIDDetailss,
  VideoRecordingUpdates,
  RtcTokenBuilders,
  deleteTeamMember,
  saveRtmTranscriptDatas,
  storeTranscriptLog,
  getRtmTranscriptDatas,
  updateRtmTranscriptDatas,
  updateGuestDatas,
  storeTranscriptMessageLog,
  updateSid
};
